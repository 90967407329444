<template>
    <div :id='"onlineService_"+query.gbType' style="height:320px;">

    </div>
</template>

<script>
import { countContractAmountGb, countContractUsePriceGb } from '@/api/dw/datav'
import * as echarts from 'echarts'
export default {
  props: {

  },
  data: function () {
    return {

      data: [],
      dataSourcePie: {},

      label: '',
      barType: '1',
      defaultType: '',
      ydata: [],
      seriesdata: [],
      query: {
        publisherId: this.$store.getters.token.userInfo.publisherId,
        companyId: this.$store.getters.token.userInfo.companyId,
        gbType: 'asset',
        // startDate: (new Date().getFullYear() + '-01-01'),
        startDate: '2020-01-01',
        endDate: (new Date().getFullYear() + '-12-31')
      }
    }
  },
  methods: {
    initComponent (label, barType, gbType) {
      this.label = label
      if (barType !== this.defaultType) {
        this.ydata = []
        this.seriesdata = []
      }
      this.barType = barType
      this.query.gbType = gbType
      this.initData()
    },
    // 加载数据
    initData () {
      if (this.barType === '1') {
        countContractAmountGb(this.query).then(res => {
          this.formatData(res)
        })
      } else if (this.barType === '2') {
        countContractUsePriceGb(this.query).then(res => {
          this.formatData(res)
        })
      }
    },
    // 格式化源数据
    formatData (res) {
      var newYdata = []
      var newSeriesData = []
      res.forEach((item, index) => {
        newYdata.push(item.name)
        if (this.seriesdata.length) {
          var child = this.seriesdata.filter(x => x.id === item.id)
          if (child.length) {
            if (child[0].value === item.number) {
              newSeriesData.push({
                id: item.id,
                number: item.number,
                itemStyle: {
                  color: '#4285F4'
                }
              })
            } else {
              newSeriesData.push({
                id: item.id,
                value: item.number,
                itemStyle: {
                  color: '#ee7c4b'
                }
              })
            }
          } else {
            newSeriesData.push({
              id: item.id,
              value: item.number,
              itemStyle: {
                color: '#ee7c4b'
              }
            })
          }
          // if(this.seriesdata[index].value!==item.value)
        } else {
          newSeriesData.push({
            id: item.id,
            value: item.number,
            itemStyle: {
              color: '#4285F4'
            }
          })
        }
      })

      this.ydata = newYdata
      this.seriesdata = newSeriesData

      this.initChart()
    },
    initChart () {
      const that = this
      if (document.getElementById('onlineService_' + this.query.gbType)) {
        this.dataSourcePie = echarts.init(document.getElementById('onlineService_' + this.query.gbType))

        const option = {
          color: '#4285F4',
          title: {
            text: this.label + 'TOP10',
            subtext: '单位：' + (this.barType === '1' ? '元' : '块*天')
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            },
            formatter: function (params) {
              let tar

              if (params[1] && params[1].value !== '-') {
                tar = params[1]
              } else {
                tar = params[0]
              }
              return tar.name + '<br/>' + (tar.value + (that.barType === '1' ? '元' : '块*天'))
            }
          },
          xAxis: {
            max: 'dataMax',
            show: false
          },
          yAxis: {
            type: 'category',
            show: false,
            data: this.ydata,
            inverse: true,
            animationDuration: 300,
            animationDurationUpdate: 300,
            max: 9 // only the largest 3 bars will be displayed
          },
          animationDuration: 0,
          animationDurationUpdate: 1000,
          animationEasing: 'linear',
          animationEasingUpdate: 'linear',
          series: [
            {
              realtimeSort: true,
              type: 'bar',
              label: {
                show: true,
                position: 'insideLeft',
                valueAnimation: true,
                color: '#fff',
                formatter: (a) => {
                  return (a.name.length > 6 ? a.name.substr(0, 5) + '...' : a.name) + '：' + a.data.value
                },
                rich: {
                  color: '#fff'
                }
              },
              data: this.seriesdata
            }
          ]
        }

        this.dataSourcePie.setOption(option, true)
      }
    },
    loadChart () {
    }
  }
}
</script>
