<style lang="less">
.ivu-card {
  border-color: #444;
  background: rgba(46, 46, 46, 0.8);
}
.ivu-card:hover {
  border-color: #444;
}
.ivu-card-head {
  padding: 5px 10px !important;
}
.ivu-card-body {
  padding: 0px !important;
}
.demo-badge-alone {
  background: #ee7c4b !important;
  top: -20px;
  right: -5px;
}
.demo-badge-new {
  background: rgba(46, 46, 46, 0.8);
  color: #ee7c4b;
}
</style>
<style lang="less" scoped>
.onlineLeft {
  width: 20%;
  max-width: 200px;
  border: 1px solid #444444;
  height: 500px;
  float: left;
}
.onlineRight {
  width: 25%;
  max-width: 250px;
  border: 1px solid #444444;
  height: 500px;
  float: left;
}
.overflowContent {
  overflow-y: auto;
  overflow-x: hidden;
}
.overflowContent::-webkit-scrollbar {
  width: 10px;
}
.onlineCenter {
  width: 55%;
  height: 500px;
  float: left;
}

.onlineCustomer {
  cursor: pointer;
  line-height: 30px;
  padding-left: 5px;
}

.customerChk {
  color: #ee7c4b;
  font-weight: 600;
}
hr {
  border: none;
  height: 1px;
  background: #444;
}
</style>
<template>
  <div>
    <Row :gutter="8">
      <i-col span="24">
        <div class="onlineLeft p-l-5 p-t-5">
          <h3 class="remark">游客消息</h3>
          <div class="overflowContent">
            <p
              v-for="(user, index) in users"
              :key="index"
              @click="changeUser(user.username)"
              :class="user.username === chkuser ? 'customerChk' : ''"
              class="onlineCustomer"
            >
             游客 {{ user.username }}
              <Badge
                v-if="user.newMessageCount"
                :count="user.newMessageCount"
                class-name="demo-badge-alone"
              ></Badge>
            </p>
          </div>
        </div>
        <div class="onlineCenter">
          <Card>
            <p slot="title">游客 {{ chkuser }}</p>
            <div class="overflowContent"
          id="onlineservice" style="height: 435px">
              <div v-for="(item, index) in messages" :key="index">
                <p
                  class="remark"
                  :class="
                    query.username === item.username
                      ? 'p-r-5  text-right'
                      : 'p-l-5'
                  "
                >
                  <Icon
                    type="md-contact"
                    v-if="query.username !== item.username"
                  />
                  <span style="line-height: 24px"
                    >{{
                      query.username === item.username ? "" : item.username
                    }}
                    {{ item.createTime }}</span
                  >
                </p>
                <p
                  :class="
                    query.username === item.username
                      ? 'p-r-5 text-right'
                      : 'p-l-5'
                  "
                >
                  {{ item.message }}
                </p>
              </div>
              <div style="height: 20px"></div>

            </div>
            <div>
              <Input
                search
                enter-button="发送"
                placeholder="Enter something..."
                v-model="usermessage"
                @on-search="add"
              />
            </div>
          </Card>
        </div>
        <div class="onlineRight p-l-5 p-t-5">
          <h3 class="remark">成交信息</h3>
          <Row class="overflowContent" style="height: 470px">
            <i-col span="24" v-for="(item,index) in platformInfo" :key="index">
              <h4>{{item.publisherName}}</h4>
              <Badge text="new" class-name="demo-badge-new" v-if="item.isNew">
                <p class="remark">{{item.brandName}} 下单成功！</p>
              </Badge>
               <p class="remark" v-else>{{item.brandName}} 下单成功！</p>
                <p class="remark">{{item.contractTime}}</p>
              <hr />
            </i-col>
          </Row>
        </div>
      </i-col>
    </Row>
    <Tabs class="text-white" v-model="bartype">
      <TabPane label="签约金额排名" name="1">

      </TabPane>
      <TabPane label="投放量排名"  name="2"> </TabPane>
    </Tabs>
     <Row :gutter="8" style="height:320px;">
          <i-col span="8">
            <echart ref="industryBarTopTen"></echart>
          </i-col>
          <i-col span="8">
            <echart ref="mediumBarTopTen"></echart>
          </i-col>
          <i-col span="8">
            <echart ref="lineBarTopTen"></echart>
            </i-col>
        </Row>
  </div>
</template>

<script>
import echart from './echart'
import { getlatestusers, getmessagepage, getsocketlocation } from '@/api/search/im'
import { getdealinfo } from '@/api/order/order'
export default {
  components: {
    echart
  },
  props: {},
  data () {
    return {
      // path: 'ws://39.108.122.13/ooh-search/websocket/',
      socket: '',

      query: {
        pageNumber: 1,
        pageSize: 200,
        username: this.$store.getters.token.userInfo.username
      },
      users: [],
      chkuser: '',
      messages: [],

      usermessage: '',

      bartype: '1',

      platformInfo: [],
      timer: ''
    }
  },
  methods: {
    initScoket () {
      if (typeof WebSocket === 'undefined') {
        alert('您的浏览器不支持socket')
      } else {
        // 实例化socket

        getsocketlocation({ username: this.query.username }).then(res => {
          this.socket = new WebSocket(res)
          // 监听socket连接
          this.socket.onopen = this.open
          // 监听socket错误信息
          this.socket.onerror = this.error
          // 监听socket消息
          this.socket.onmessage = this.getMessage
        })
      }
    },
    open: function () {
      // console.log('socket连接成功')
    },
    error: function () {
      // console.log('连接错误')
    },
    getMessage: function (msg) {
      const msginfo = JSON.parse(msg.data)
      if (
        !this.users.filter((x) => x.username === msginfo.fromusername).length
      ) {
        this.users.push({
          status: 0,
          username: msginfo.fromusername,
          newMessageCount: 1
        })
      } else {
        if (this.chkuser === msginfo.fromusername) {
          this.initMessages()
        } else {
          this.users.filter((x) => x.username === msginfo.fromusername)[0].newMessageCount += 1
        }
      }
    },
    // send: function () {
    //   this.socket.send()
    // },
    close: function () {
      // console.log('socket已经关闭')
    },
    initusers () {
      getlatestusers({
        username: this.$store.getters.token.userInfo.username
      }).then((res) => {
        res.forEach(item => {
          item.newMessageCount = 0
        })
        this.users = res
        if (res.length) {
          this.chkuser = res[0].username
          this.initMessages()
        }
      })
    },
    initMessages () {
      this.query.tousername = this.chkuser
      getmessagepage(this.query).then((res) => {
        this.messages = res.list.sort((a, b) => (a.id - b.id))
        var div = document.getElementById('onlineservice')
        div.scrollTop = div.scrollHeight
      })
    },
    loadEchart () {
      this.$refs.industryBarTopTen.initComponent('行业', this.bartype, 'inter_industry')
      this.$refs.mediumBarTopTen.initComponent('媒体', this.bartype, 'resourceType')
      this.$refs.lineBarTopTen.initComponent('线路', this.bartype, 'asset')
    },
    changeUser (username) {
      this.users.filter((x) => x.username === username)[0].newMessageCount = 0

      this.chkuser = username
      this.initMessages()
    },
    add () {
      if (this.usermessage) {
        const message = {
          message: this.usermessage,
          to: this.chkuser,
          username: this.query.username
        }
        this.socket.send(JSON.stringify(message))
        this.initMessages()
        this.usermessage = ''
      }
    },

    // 获取平台成交信息
    roloadplatforminfo () {
      const that = this
      getdealinfo({ quantity: 30 }).then(res => {
        if (that.platformInfo.length) {
          res.forEach(item => {
            if (that.platformInfo.filter(x => x.contractTime === item.contractTime && x.brandId === item.brandId && x.publisherId === item.publisherId).length) {
              item.isNew = false
            } else {
              item.isNew = true
            }
          })

          // 强行最新一条记录
          if (res.length) {
            res[0].isNew = true
          }

          if (res.filter(x => x.isNew).length) {
            that.platformInfo = res

            that.loadEchart()
          }
        } else {
          that.platformInfo = res.map(item => {
            item.isNew = false
            return item
          })
          if (that.platformInfo.length) {
            that.platformInfo[0].isNew = true
          }
          that.loadEchart()
        }
      })
    }

  },
  created () {
    this.initScoket()
    this.initusers()
    this.roloadplatforminfo()
  },
  mounted () {
    this.timer = setInterval(this.roloadplatforminfo, 60000)
  },
  watch: {
    bartype () {
      this.loadEchart()
    }
  },
  beforeDestroy () {
    clearInterval(this.timer)
  },
  computed: {}
}
</script>
