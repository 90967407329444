import request from '@/utils/requestV2'
const qs = require('qs')

// 获取我的最近联系人及当前登录状态
export function getlatestusers (data) {
  return request({
    url: '/ooh-search/v1/im/getlatestusers',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取最近聊天记录
export function getmessagepage (data) {
  return request({
    url: '/ooh-search/v1/im/getmessagepage',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取webscoket请求地址
export function getsocketlocation (data) {
  return request({
    url: '/ooh-search/v1/im/getsocketlocation',
    method: 'post',
    data: qs.stringify(data)
  })
}
